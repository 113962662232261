  .container {
    display: flex;
    justify-content: center;
  }
  
  /* Rectangle Card Layout */
  .rectangle {
    background: url(../../assets/images/talksCardBack.svg);
    width: 380px;
    height: 300px;
    border-radius: 4px;
    box-shadow: 0px 20px 50px #d9dbdf;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-top: 50px;
  }
  .rectangle:hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  }

  .mask {
    clip: rect(0px, 460px, 220px, 0px);
    border-radius: 4px;
    position: absolute;
  }
  
  /* Card  Styling */
  .talk-card-title {
    color: white;
    margin: auto;
    text-align: left;
    margin-top: 25px;
    padding: 0px 0px 40px 30px;
    font-size: 30px;
    line-height: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .talk-card-subtitle {
    margin-top: 35px;
    text-align: justify;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    color: #7f8287;
    line-height: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .talk-button {
    background-color: #a066fb;
    color: white;
    width: 90px;
    padding: 10px 18px;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
    display: block;
    margin-top: 20px;
    font-size: 14px;
    cursor: pointer;
  }
  .card-footer-button-div{
    display: flex;
    justify-content: space-around;
  }

  /* Media Query */
  @media (max-width: 1380px) {
    .card-title {
      font-size: 25px;
    }
  }
  @media (max-width: 768px) {
    .mask {
      clip-path: none;
      position: relative
    }
    .talk-card-title {
      margin-top: 30px;
    }
    .talk-card-image {
      width: 100%;
    }
  
    .square {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  