.main-button {
  background-color: #55198b;
  border: solid 1px #55198b;
  color: white;
  font-weight: 700;
  width: max-content;
  padding: 13px 22px;
  margin-right: 50px;
  text-transform: uppercase;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
}
.main-button:hover {
  background-color: #ffffff;
  color: black;
  transition: ease-in 0.3s;
}
.project-button{
  display: flex;
  justify-content: center;
  margin-top: 20px;

}
/* Media Query */
@media (max-width: 768px) {
  .main-button {
    font-size: 15px;
    padding: 12px 18px;
    margin-right: 0px;
  }
}
@media (max-width: 320px) {
  .main-button {
    font-size: 12px;
  }
}
